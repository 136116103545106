document.addEventListener('DOMContentLoaded', function () {
    const notification = document.querySelector('.js-top-notification');
    const notificationName = 'h1-' + (notification.dataset.notification || '');
    const getNotification = localStorage.getItem('notification-closed');

    // Toggle 'hide' class when DOM is loaded unless a cookie is set
    if (notification && !getNotification) {
        notification.classList.toggle('hide');
    }

    // Add click event listener to close button
    const closeButton = document.querySelector('.js-close-notification');
    if (closeButton) {
        closeButton.addEventListener('click', function () {
            // Toggle 'hide' class when close button is clicked
            if (notification) {
                notification.classList.toggle('hide');
                // Set a cookie when the close button is clicked
                localStorage.setItem('notification-closed', notificationName);
            }
        });
    }
});
