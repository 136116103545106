document.addEventListener('DOMContentLoaded', () => {
  const body = document.body;
  const mobileTrigger = document.querySelector('.js-mobile-trigger');
  const mainNavigation = document.querySelector('.main-navigation');
  const items = mainNavigation.querySelectorAll('.menu-item-has-children');
  const subNavigations = mainNavigation.querySelectorAll('.sub-menu');
  const topTitle = document.querySelector('.mobile-navigation-top__title');
  const backButton = document.querySelector('.js-close-sub');

  function openMobileNavigation() {
    mobileTrigger.addEventListener('click', () => {
      body.classList.toggle('mobile-navigation-open');
      if (!body.classList.contains('mobile-navigation-open')) {
        closeSubNavigation();
        topTitle.textContent = 'Menu';
      }
    });
  }

  function mobileSubItems() {
    backButton.addEventListener('click', () => {
      closeSubNavigation();
      topTitle.textContent = 'Menu';
    });

    items.forEach(item => {
      const link = item.querySelector('a');
      const subNavigation = item.querySelector('.sub-menu');
      if (link && subNavigation) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          closeSubNavigation();
          backButton.classList.add('d-block');
          topTitle.textContent = link.textContent;
          subNavigation.classList.toggle('open');
        });
      }
    });
  }

  function closeSubNavigation() {
    subNavigations.forEach(subNav => {
      subNav.classList.remove('open');
    });
    backButton.classList.remove('d-block');
  }

  openMobileNavigation();
  mobileSubItems();
});
