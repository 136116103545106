/*
  Add this module because of the glider module.
  It gives this message in pagespeed "Does not use passive listeners to improve scrolling performance"
*/
import '../node_modules/default-passive-events';

import './plugin/mobile-navigation'
import './plugin/foldout-navigation'
import './plugin/top-notification'
import './plugin/search'
import './plugin/glider'
import './plugin/accordion'
