import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', () => {
  const body = document.body;
  const mainNavigation = document.querySelector('.js-main-navigation');
  const closeMainNavigation = document.querySelector('.js-close-foldout-navigation');
  const items = mainNavigation.querySelectorAll('.menu-item-has-children');
  const foldoutModal = new Modal(document.getElementById('foldoutNavigation'));
  const currentActiveItem = document.querySelector('.current-menu-item, .current-category-ancestor, .current-menu-ancestor');
  const mobileVisible = document.querySelector('.js-mobile-trigger').offsetLeft;
  const topNotificationBar = document.querySelector('.js-top-notification');

  function handleItemClick(item) {
    const foldoutParent = item.querySelector('a');
    const NavSubItems = item.querySelector('.sub-menu').outerHTML;

    placeholders(foldoutParent.innerHTML, NavSubItems);

    removeClass();
    item.classList.add('active-foldout-item');

    if (topNotificationBar) {
      topNotificationBar.classList.add('hide');
    }

    foldoutModal.show();
  }

  function placeholders(title, items) {
    const plTitle = document.querySelector('.js-foldout-title');
    const plItems = document.querySelector('.js-foldout-menu');

    plTitle.innerHTML = title;
    plItems.innerHTML = items;
  }

  function removeClass() {
    items.forEach(item => item.classList.remove('active-foldout-item'));

    if (currentActiveItem) {
      currentActiveItem.classList.remove('current-menu-item', 'current-category-ancestor', 'current-menu-ancestor');

      setTimeout(() => {
        if (!foldoutModal._isShown) {
          currentActiveItem.classList.add('current-menu-item');
        }
      }, 500);
    }
  }

  function foldoutNavigation() {
    if (body.classList.contains('mobile-navigation-open') || mobileVisible > 0) {
      return;
    }

    items.forEach(item => {
      item.addEventListener('click', () => handleItemClick(item));
    });

    closeMainNavigation.addEventListener('click', () => {
      removeClass();

      if (topNotificationBar) {
        topNotificationBar.classList.remove('hide');
      }
    });
  }

  foldoutNavigation();
});
