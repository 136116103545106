function accordionToggle() {
  const accordionCurrentItem = this.parentElement;
  const accordionWrapper = this.closest('.wp-block-yoast-faq-block');
  const accordionChildren = accordionWrapper.children;

  for (const child of accordionChildren) {
    child.classList.remove('is-open');
  }

  accordionCurrentItem.classList.toggle('is-open');
}

function accordion() {
  const accordionTitle = document.querySelectorAll('.schema-faq-question');

  accordionTitle.forEach(title => {
    title.addEventListener('click', accordionToggle, false);
  });
}

function openFirstFaqQuestion() {
  const faqQuestion = document.querySelector('.schema-faq .schema-faq-section');

  if (faqQuestion) {
    faqQuestion.classList.add('is-open');
  }
}

window.addEventListener('DOMContentLoaded', () => {
  accordion();
  openFirstFaqQuestion();
});
