document.addEventListener('DOMContentLoaded', () => {
  const searchTrigger = document.querySelector('.js-btn-search');
  const searchForm = document.querySelector('.js-search-form');

  function showSearch() {
    searchTrigger.addEventListener('click', () => {
      searchTrigger.classList.toggle('open');
      searchForm.classList.toggle('open');
    });
  }

  if (searchTrigger) {
    showSearch();
  }
});
