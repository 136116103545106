import Glider from '../../node_modules/glider-js/glider'

const blogSlider = document.querySelector('.js-blog-slider')
const blogSliderRelated = document.querySelector('.js-blog-slider-related')
const partnerSlider = document.querySelector('.js-partner-slider')

if (blogSlider) {
  /* eslint-disable no-new */
  new Glider(blogSlider, {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    itemWidth: 'auto',
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  })
}

if (blogSliderRelated) {
  new Glider(blogSliderRelated, {
    slidesToShow: 1.2,
    slidesToScroll: 1,
    itemWidth: 'auto',
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  })
}

if (partnerSlider) {
  new Glider(partnerSlider, {
    slidesToShow: 'auto',
    slidesToScroll: 1,
    itemWidth: 'auto',
    draggable: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      }
    ]
  })
}
